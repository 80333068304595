<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" centered :title="title">
        <b-form @submit.prevent="confirm">
            <div class="mb-4 text-center">
                <b-input v-model="inputValue" type="text"/>
            </div>

            <confirm-cancel-buttons @confirm="confirm" @cancel="hideModal"/>
        </b-form>
    </b-modal>
</template>

<script>
    import modalMixin from '@/mixin/modalMixin';

    const ConfirmCancelButtons = () => import('@/components/ConfirmCancelButtons');

    export default {
        name: "EditText",
        components: {ConfirmCancelButtons},
        mixins: [modalMixin],
        props: {
            title: {
                type: String,
                default: 'Édition'
            },
            value: {
                type: String,
                default: null
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            modalRef: 'EditTextModal',
            inputValue: null
        }),
        methods: {
            confirm() {
                this.callback(this.inputValue);
                this.hideModal();
            }
        },
        mounted() {
            this.inputValue = this.value;
        }
    }
</script>
